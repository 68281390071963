//import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import EntryPage from './components/EntryPage';
import './App.css';
import Contact from './components/Contact';
import Faq from './components/Faq';
import AboutUs from './components/AboutUs';
import Gallery from './components/Gallery';
import Quotations from './components/Quotations';
import Media from './components/Media';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<EntryPage />}></Route>
          <Route path='/contact-us' element={<Contact />}></Route>
          <Route path='/media' element={<Media />}></Route>
          <Route path='/faq' element={<Faq />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/gallery' element={<Gallery />}></Route>
          <Route path='/quotation-request' element={<Quotations />}></Route>
          <Route path="*" element={<PageNotFound />} />
          {/*<Route path="*" element={<Navigate to="/" />} />*/}
      </Routes>

    </div>
  );
}

export default App;
