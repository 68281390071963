import React, { useState, useEffect } from 'react';
import "./css/media.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import ReactPlayer from 'react-player/lazy'
import ReactPaginate from 'react-paginate';
import BreadCrum from './BreadCrum';
import { Link } from 'react-router-dom';

function Media() {
    const [ spinner, setSpinner ] = useState(true);

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | Media";
        
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='container-lg pt-4 pb-4'>
                        <h1 className='fs-4 lead text-success fw-bold'>Our Media Outreaches</h1>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <ReactPlayer
                                    className='react-player bg-success'
                                    //light={<img src='images/network.png' width="100%" alt='Thumbnail' />}
                                    url='https://www.youtube.com/watch?v=jkhVmKkphN8'
                                    width='100%'
                                    height='230px'
                                    controls={true}
                                />
                                <p className='pt-4 fw-bolder'>Locally Made Biodegradable Sanitary MakaPads - "empowering people. Award" Winner</p>
                            </div>
                            <div className='col-lg-7'>
                                <h3 className="fg fs-6 pb-0 fw-bold">Online Events</h3>
                                <h2 className="fs-4 text-success fw-bold">Click on any of the links to view some of our online stories.</h2>
                                <Link className='d-block' to={"https://empowering-people-network.siemens-stiftung.org/solutions/makapads/"}>1. Facts about MakaPads</Link>
                                <Link className='d-block' to={"https://twitter.com/Emp_Ppl_Award/status/1368880017934057474"}>2. Meet our CEO</Link>
                                <Link className='d-block' to={"https://enpact.org/news-archive/nnassuuna-mirembe-turned-to-mentorship-to-scale-the-impact-of-her-companys-inventions/"}>3. Nnassuuna Mirembe turned to mentorship to scale the impact of her company’s inventions</Link>
                                <Link className='d-block' to={"https://www.designwithoutborders.com/work/productdesign/makapads/"}>4. Low cost sanitary pads for girls and Women</Link>
                                <Link className='d-block' to={"https://www.monitor.co.ug/uganda/lifestyle/reviews-profiles/how-it-works-makapads-1516474"}>5. How Makapads Work</Link>
                                <Link className='d-block' to={"http://www.ip-watch.org/2014/03/13/makapads-helping-disadvantaged-girls-and-women-in-uganda/"}>6. MakaPads Helping Disadvantaged Girls And Women In Uganda</Link>
                                <Link className='d-block' to={"https://repository.tudelft.nl/islandora/object/uuid%3Ac7719610-8ae7-45ff-9305-f7300f6055cc"}>7. A launch strategy to increase awareness and availability of MakaPads in rural Uganda</Link>
                                <Link className='d-block' to={"https://www.dw.com/en/ugandans-make-sanitary-pads-from-papyrus/video-59067235"}>8. How Ugandans make Sanitary pads from Papyrus</Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default Media