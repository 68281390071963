import React, { useState, useEffect } from 'react';
import "./css/notfound.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import ReactPlayer from 'react-player/lazy'
import ReactPaginate from 'react-paginate';
import BreadCrum from './BreadCrum';
import { Link } from 'react-router-dom';

function PageNotFound() {
    const [ spinner, setSpinner ] = useState(true);

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | Page Not Found";
        
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='notFound text-center'>
                        <h1>404</h1>
                        <p className='text-danger fw-bold'>Page Not Found</p>
                        <Link to={"/"} className='btn btn-lg d-none btn-warning text-white'>Homepage</Link>
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default PageNotFound