import React, { useState, useEffect } from 'react';
import "./css/faq.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; 
import { domain } from './Domain';
import Load from './Load';

import BreadCrum from './BreadCrum';

function QA(props){
    return(
        <>
            <div className='d-flex align-items-center'>
                <p><i class="text-warning fw-bold fs-5 fa-regular fa-circle-question"></i></p>
                <p className='ms-3 text-success' dangerouslySetInnerHTML={{ __html: props.question }}></p>
            </div>
            <div className='d-flex align-items-center'>
                <p className='me-3 text-secondary' dangerouslySetInnerHTML={{ __html: props.answer }}></p>
                <p className='d-none'><i class="fa-solid fa-reply text-success fw-bold fs-5"></i></p>
            </div>
            <hr className=''/>
        </>
    )
}

export function Benefits(props){
    return(
        <div className='d-flex col-6'>
            <p><i class="fa-solid text-warning fa-square-check"></i></p>
            <p className='ms-3 text-secondary'>{props.benefit}</p>
        </div>
    )
}

function Faq(props) {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };
    const [ spinner, setSpinner ] = useState(true);
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([])
    // It will be executed before rendering

    const sendQtn = () => {
        let form = document.getElementById("question_forms");

            let data = new FormData(form);
            document.getElementById("responses").innerHTML = "sending ...";
            const requestOptions = {
                method: 'POST',
                credentials: "omit",
                body: data
            };
            fetch(`${domain}/questions-ask`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                form.reset();
                document.getElementById("responses").innerHTML = data['message'];
            })
            .catch((err) => console.log(err))
   
    }

    async function fetchData() {
        const response = await fetch(`${domain}/questions`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setQuestions(jsonData);
        setLoading(false);
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 100);
        document.title = "Makapads | FAQ";
        fetchData();
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='container-lg row m-auto'>
                        <div className='col-lg-12'>
                            <button className='btn btn-danger mt-4' data-bs-toggle="modal" data-bs-target="#exampleModal">Have a question ?</button>
                            <h1 className='fs-4 pt-4 pb-2 fw-bold'>Frequently Asked Questions (FAQs)</h1>
                            {loading ? <Load /> :
                            <>
                            {questions.length > 0 && (
                                questions.map(data => (
                                    <QA
                                        question={data.qtn}
                                        answer={data.ans}
                                    />
                                ))
                            )}
                            </>
                            } 
                        </div>
                        <div className='col-lg-5 d-none'>
                            <h1 className='fs-4 pt-4 pb-2 fw-bold text-success'>Benefits of <span className='text-danger fw-bold fs-3'>MakaPads</span></h1>
                            <div className='row m-auto'>
                            </div>
                            {/*
                            <Carousel 
                                swipeable={false}
                                draggable={false}
                                centerMode={true} //Shows the next items and previous items partially.
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={props.deviceType !== "mobile" ? true : false}
                                autoPlaySpeed={4000}
                                keyBoardControl={true}
                                customTransition="transform 300ms ease-in-out"
                                slidesToSlide = {1}
                                transitionDuration={1000}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType={props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                    <div className='position-relative mt-2 pb-2' >
                                        <div className='text-center' style={{display: loading ? "block" : "none"}}>
                                            <div class="spinner-grow text-danger" style={{width: "2rem", height: "2rem",position:"absolute", top: "50%"}} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <a class="a_tag" href="/images/about.jpeg" data-lightbox="gallery" data-title="no title">
                                            <img style={{visibility: loading ? "hidden" : "visible"}} onLoad={imageLoaded} src='/images/about.jpeg' data-src="/images/about.jpeg" alt='makapad-image'/>
                                        </a>
                                    </div>
                                    <div className='position-relative mt-2 pb-2' >
                                        <div className='text-center' style={{display: loading ? "block" : "none"}}>
                                            <div class="spinner-grow text-danger" style={{width: "2rem", height: "2rem",position:"absolute", top: "50%"}} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <a class="a_tag" href="/images/abt.jpeg" data-lightbox="gallery" data-title="no title">
                                            <img style={{visibility: loading ? "hidden" : "visible"}} onLoad={imageLoaded} src='/images/abt.jpeg' data-src="/images/abt.jpeg" alt='makapad-image'/>
                                        </a>
                                    </div>
                                    <div className='position-relative mt-2 pb-2' >
                                        <div className='text-center' style={{display: loading ? "block" : "none"}}>
                                            <div class="spinner-grow text-danger" style={{width: "2rem", height: "2rem",position:"absolute", top: "50%"}} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <a class="a_tag" href="/images/img1.jpeg" data-lightbox="gallery" data-title="no title">
                                            <img style={{visibility: loading ? "hidden" : "visible"}} onLoad={imageLoaded} src='/images/img1.jpeg' data-src="/images/img1.jpeg" alt='makapad-image'/>
                                        </a>
                                    </div>
                                
                            </Carousel>
                        */}
                        </div>
                    </div>
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Please submit your question.</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form method='post' id='question_forms'>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Name</span>
                                            <input type="text" name='name' class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
                                        </div>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text" id="basic-addon1">Email</span>
                                            <input type="email" name='email' class="form-control" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1"/>
                                        </div>
                                        <textarea name='question' className='form-control mb-3' rows={5} placeholder='Enter your question here ....'></textarea>
                                        <p className="text-info fs-6 fw-bold" id='responses'></p>
                                        <button type="button" className="btn btn-success" onClick={sendQtn}>Submit question</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default Faq