import React from 'react'
import "./css/loader.css"

function Loader() {
  return (
    <div className='parent'>
        <div className="lds-facebook"><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loader