import React, { useState, useEffect } from 'react';
import "./css/gallery.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import ReactPaginate from 'react-paginate';
import BreadCrum from './BreadCrum';
import { domain } from './Domain';
import Load from './Load';

function Image(props){
    return(
        <div className='position-relative galleryDiv'>
            <a class="a_tag" href={props.image} data-lightbox="gallery" data-title={props.title}><img class="img show" src={props.image} data-src={props.image} alt="img"/></a>
            <div className='text-white'>
                <i class="fas fa-image"></i>
                <p className='mb-1' dangerouslySetInnerHTML={{ __html: props.title }}></p>
                <small className='text-white'>Date when it was posted</small>
            </div>
        </div>
    )
}
function Gallery() {
    const [ spinner, setSpinner ] = useState(true);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    // It will be executed before rendering

    async function fetchData() {
        const response = await fetch(`${domain}/gallery`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setData(jsonData);
        setLoading(false);
    }

    const PER_PAGE = 4;
    const offset = currentPage * PER_PAGE;
    const currentPageData = data.slice(offset, offset + PER_PAGE);
    const pageCount = Math.ceil(data.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | Gallery";
        fetchData();
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='container-lg m-auto pt-2 pb-2'>
                        <h1 className='fs-4 pt-3 pb-3 fw-bold'>Our Gallery Items</h1>
                        <div className='d-flex flex-wrap flexed justify-content-start'>
                        {loading ? <Load /> :
                            <>
                            {currentPageData.length > 0 && (
                                currentPageData.map(data => (
                                    <Image 
                                        key={data.id}
                                        image={domain+data.img}
                                        title={data.details}/>
                                ))
                            )}
                            </>}
                        </div>
                        <ReactPaginate
                            previousLabel={"← Previous"}
                            nextLabel={"Next →"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default Gallery