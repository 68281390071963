import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./css/carousel.css"

const dummyData = [
    {
        "id":1,
        "image":"/images/top.png",
        "title":"MHM Training",
        "description":"MakaPads is committed to breaking barriers and stigma."
    }
]
export const Data = (props) =>{
    return(
        <div key={props.id} className="carousel-item active" data-bs-interval="10000">
            <img src={props.image} alt="tagged" />
            <div className="carousel-caption title d-md-block">
                <h5 className="fw-bold lead">{props.title}</h5>
                <p className="lead">{props.description}</p>
            </div>
        </div>
    )
}
export const Datas = (props) => {
    return (
        <div key={props.id} className="carousel-item" data-bs-interval="10000">
            <img src={props.image} alt="tagged" />
            <div className="carousel-caption d-md-block">
                <h5 className="fw-bold fs-2 lead">{props.title}</h5>
                <p className="lead">{props.description}</p>
                <div className='d-none d-md-block'>
                    <Link to="/contact-us" className="btn btn-lg fs-6 btn-success rounded-0 me-4">Request a Quote</Link>
                    <Link to="/contact-us" className="btn btn-lg fs-6 btn-outline-warning rounded-0">Contact Us</Link>
                </div>
            </div>
        </div>
    )
}

export const Carousel = () => {

    const [carousel, setUsers] = useState([])
    const [spinner, setSpinner] = useState(false); 

    const fetchData = () => {
        setSpinner(true);
        fetch("https://api.stratcomug.com/carousel/",{
            mode: 'cors',
            //credentials: "include", // include, *same-origin, omit
        })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            setUsers(result)
            setSpinner(true);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    useEffect(() => {
        //fetchData()
        setUsers(dummyData);
        setSpinner(true);
    }, [])
  return (
    <div id="carouselExampleCaptions" className="carousel slide carousel-fade div" data-bs-ride="carousel">
        <div className="carousel-indicators">
            {carousel.map((carusel, index) => (
                index === 0 ? <button key={index} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={parseInt(index)} className="active" aria-current="true" aria-label={"Slide " + parseInt(index + 1)}></button> : <button key={index} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={parseInt(index)} aria-label={"Slide " + parseInt(index+1)}></button>
            ))}
        </div>
        <div className="carousel-inner" >
              {carousel.length > 0 && (
                  <>
                      {carousel.map((carusel, index) => (
                          index === 0 ? <Data key={carusel.id} id={carusel.id} title={carusel.title} description={carusel.description} image={carusel.image} /> : <Datas key={carusel.id} id={carusel.id} title={carusel.title} description={carusel.description} image={carusel.image} /> 
                      ))}
                  </>
              )}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className ="visually-hidden">Next</span>
        </button>
    </div>
  )
}
