import React, { useState, useEffect } from 'react';
import "./css/about.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import { domain } from './Domain';

function StaffMember(props){
    return(
        <>
            <div class="staff m-2">
                <div>
                    <img src={props.image} class="mx-auto d-block img-thumbnail" alt="user_image"/>
                    <figcaption class="fs-5 text-secondary lead fw-bold p-2">{props.name}</figcaption>
                    <p className='text-warning fw-bold lead fs-6'>{props.position}</p>
                </div>
            </div>
        </>
    )
}

function AboutUs() {
    const [ spinner, setSpinner ] = useState(true);
    const [loading, setLoading] = useState(true);
    const [staff, setStaff] = useState([]);
    // It will be executed before rendering

    async function fetchData() {
        const response = await fetch(`${domain}/staff`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        //console.log(jsonData);
        setStaff(jsonData);
    }

    const imageLoaded = () => {
        setLoading(false);
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | About-us";
        fetchData();
        /*
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        //script.charset="utf-8";
        script.async = true;
        document.head.appendChild(script);
        setTwitter(true);
        */
        }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='background_about text-white text-center'>
                        <h1 className='fs-2 fw-bold'>About Us</h1>
                    </div>
                    <div className='container-lg position d-block d-lg-flex align-items-end pb-4'>
                        <div className='col-lg-7 justified'>
                            <h1 className='fs-4 fw-bold text-success fw-bold pt-4'>MakaPads</h1>
                            <p className='lead fs-6'>Everyday girls and women are excluded from important due to their periods. We aim to see that girls stay in school and women are not held back.</p>
                            <p className='lead fs-6'>MakaPads provides a natural absorbent pad that that provides hygienic protection. We follow this up by teaching women and girls how to manage their periods and make informed decisions about management of their menstrual hygiene. We engage community members including teachers and village leaders in the discussions to support girls in their transition to womanhood. </p>
                            <p className='lead fs-6'>We create employment opportunities in production hubs producing MakaPads in rural areas and utilize renewable energy in the rainwater in production. </p>
                            <p className='lead fs-6'>Our production hubs are constructed using climate responsive materials.</p>
                        </div>
                        <div className='col-lg-5 pt-4'>
                            <img className='about-side' src='/images/abt.jpeg' alt='about-image' />
                        </div>
                    </div>
                    <div className='bg-light pt-4 pb-4 background'>
                        <div className='row row-cols-1 row-cols-lg-3 container-lg m-auto'>
                            <div className='col mb-2'>
                                <div className='p-3'>
                                    <h1 className='fs-4 fw-bold text-white'>Mission <hr className='mt-1 text-white mb-1' style={{width:"20%"}} /></h1>
                                    <p className='lead fs-6'>To provide Environmentally Sustainable Technology for Everyday Needs.</p>
                                </div>
                            </div>
                            <div className='col mb-2'>
                                <div className='p-3'>
                                    <h1 className='fs-4 fw-bold text-white'>Vision <hr className='mt-1 text-white mb-1' style={{width:"20%"}} /></h1>
                                    <p className='lead fs-6'>A world where Technology betters the Environment.</p>
                                </div>
                            </div>
                            <div className='col mb-2'>
                                <div className='p-3'>
                                    <h1 className='fs-4 fw-bold text-white'>Core Values <hr className='mt-1 text-white mb-1' style={{width:"30%"}} /></h1>
                                    <ul style={{listStyle: "none"}} className='p-0'>
                                        <li className='lead fs-6'>Integrity</li>
                                        <li className='lead fs-6'>Innovation</li>
                                        <li className='lead fs-6'>Environmental Sustainability</li>
                                        <li className='lead fs-6'>Equality</li>
                                        <li className='lead fs-6'>Teamwork</li>
                                        <li className='lead fs-6'>Excellence</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-lg text-center m-auto'>
                        <h1 className='fs-4 pt-4 pb-4 text-success fw-bold'>Meet Our Team</h1>
                        <div className="d-flex justify-content-center flex-wrap m-auto g-2">
                            {staff.length > 0 && (
                                staff.map(data => (
                                    <StaffMember
                                        key={data.id} 
                                        name={data.name}
                                        image={`${domain}`+data.image}
                                        position={data.position}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
  )
}

export default AboutUs