import React, {useEffect, useState} from "react";
import Carousel from 'react-multi-carousel';
import { Link } from "react-router-dom";
import { domain } from "./Domain";
import 'react-multi-carousel/lib/styles.css'; 
import "./css/partners.css"

function Partners(props) {
  const [partners, setPartners] = useState([]);
    // It will be executed before rendering

    async function fetchData() {
        const response = await fetch(`${domain}/partners`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setPartners(jsonData);
    }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
      fetchData();
  }, []);

  return (
    <div>
      <Carousel 
        swipeable={false}
        draggable={false}
        centerMode={true} //Shows the next items and previous items partially.
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        slidesToSlide = {1}
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
          {partners.length > 0 && (
              partners.map(data => (
                <Link to={data.link}><img src={domain+data.logo} className='w-100' alt={data.name} /></Link>
              ))
          )}    
      </Carousel>
    </div>
  )
}

export default Partners