import React, { useState, useEffect } from 'react';
import "./css/quotation.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { domain } from './Domain';
//import BreadCrum from './BreadCrum';
//import Partners from './Partners';

function Quotations() {
    const [ spinner, setSpinner ] = useState(true);
    /*const [loading, setLoading] = useState(true);

    const imageLoaded = () => {
        setLoading(false);
    }*/

    const sendQuotation = () => {
        let form = document.getElementById("quotation_form");
        form.onsubmit = function (event) {
            event.preventDefault();
            let data = new FormData(form);
            document.getElementById("responses").innerHTML = "sending ...";
            const requestOptions = {
                method: 'POST',
                credentials: "omit",
                body: data
            };
            fetch(`${domain}/quotation`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                form.reset();
                document.getElementById("responses").innerHTML = data['message'];
            })
            .catch((err) => console.log(err))
        }
    }

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | Quotations";
        
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className='row container-lg m-auto pt-4'>
                        <div className="col-lg-6 lead">
                            <div className="div">
                                <h3 className="fg fs-6 pt-4 pb-0 fw-bold">Request a quote</h3>
                                <h2 className="fs-4 text-success fw-bold">Need A Free Quote? Please Contact Us At Any Time</h2>
                                <div className='d-flex pt-2 justify-content-between'>
                                    <p className='fs-6'><i class="fa-solid fa-reply text-success fw-bold fs-6 pe-3"></i> Reply within 24 hours</p>
                                    <p className='fs-6'><i class="fa-solid fa-phone text-success fw-bold fs-6 pe-3"></i> 24 hrs telephone inquiries</p>
                                </div>
                                <p className='fs-6 pb-4'>If you any question about anything regarding our sanitary pads, please feel free to reach out to us at any time of convinience so we address your concerns on time. We are committed to providing the best Sanitary pads on the Market</p>
                                <div className='d-flex align-items-center justify-content-start'>
                                    <p className='bg-success p-3 text-white me-3 rounded'><i class="fa-solid fa-phone"></i></p>
                                    <div>
                                        <p className='fs-6 m-0 fw-bold text-secondary'>Call to ask any question</p>
                                        <p><a className='fs-6 text-success fw-bold' href='tel:+0393228112'>0393228112</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <h2 className="fs-5 text-muted mb-4">Please complete the form below for any price quotes for our products</h2>
                            <form id='quotation_form'>
                                <p className="text-info fs-6 fw-bold" id='responses'></p>
                                <div class="form-floating mb-3">
                                    <input type="text" name="name" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                    <label for="floatingInput">Client Name</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="email" name='email' class="form-control" id="floatingInput" placeholder="name@example.com"/>
                                    <label for="floatingInput">Email address</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="tel" name='phone' class="form-control" id="floatingPassword" placeholder="Password"/>
                                    <label for="floatingPassword">Phone number</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <select class="form-select" name='category' id="floatingSelect" aria-label="Floating label select example">
                                        <option selected hidden className='select'>Select a Category under which you would like to purchase</option>
                                        <option value="school">School</option>
                                        <option value="health facility">Health Facility</option>
                                        <option value="individual">Individual</option>
                                        <option value="company">Company</option>
                                    </select>
                                    <label for="floatingSelect">Category of purchase</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <textarea class="form-control" name='inquiry' style={{height: "200px"}} placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                    <label for="floatingTextarea">Any other inquiry, please specify</label>
                                </div>
                                <button className='btn btn-success mb-3' onClick={sendQuotation}>submit Quotation Request <i class="fa-solid fa-right-to-bracket"></i></button>
                            </form>
                        </div>
                    </div>
                    
                    <Footer />
                </div>
            }
        </> 
  )
}

export default Quotations