import React, { useState, useEffect } from 'react';
import "./css/contact.css"
import Loader from './Loader';
import Navbar from './NavBar';
import Footer from './Footer';
import { domain } from './Domain';

function Contact() {
    const [ spinner, setSpinner ] = useState(true);

    const sendMessage = () => {
        let form = document.getElementById("contact_form");
        form.onsubmit = function (event) {
            event.preventDefault();
            let data = new FormData(form);
            document.getElementById("responses").innerHTML = "sending ...";
            const requestOptions = {
                method: 'POST',
                credentials: "omit",
                body: data
            };
            fetch(`${domain}/contact-us`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                form.reset();
                document.getElementById("responses").innerHTML = data['message'];
            })
            .catch((err) => console.log(err))
        }
    }
    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | Contact-us";
        
    }, []);
    return (
        <>  
            {spinner ? <Loader /> : 
                <div>
                    <Navbar />
                    <div className="row m-auto container pb-4 pt-4 lead">
                        <div className="col-lg-7 div">
                            <h2 className="fg fs-4 pt-4 pb-2 fw-bold">Contact Us</h2>
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="fa-solid fs-6 fa-location-dot bg text-white p-2 rounded me-3"></i>
                                <address className="text-muted fs-6">
                                    <span>Address</span><br />
                                    <span>Plot 2266, Kawempe, Kalule Zone</span>, <br />
                                    <span>Kampala Uganda</span>
                                </address>
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="fa-solid fs-6 fa-phone bg text-white p-2 rounded me-3"></i>
                                <address className="text-muted fs-6">
                                    <span>phone</span><br />
                                    <span>+256 393228112</span><br />
                                </address>
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                <i className="fa-solid fs-6 fa-envelope bg text-white p-2 rounded me-3"></i>
                                <address className="text-muted fs-6">
                                    <span>Email</span><br />
                                    <a href="mailto:pkimera@t4tafrica.co">pkimera@t4tafrica.co</a>
                                </address>
                            </div>
                            <iframe className="pt-4" width="100%" height="300px" frameborder="0" style={{border:0}}
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=pic hill primary school, kampala"
                                allowfullscreen="true"></iframe>
                        </div>
                        <div className="col-lg-5 div">
                            <h2 className="fg fs-4 pt-4 pb-2 fw-bold">Send Us a Message</h2>
                            <p className="text-muted lead fs-6">For any inquiries about Stratcom Internship placements, please send us a
                                message. We repond within 24hrs period.</p>
                            <form method="post" id='contact_form'>
                                <p className="text-info fs-6 fw-bold" id='responses'></p>
                                <div className="form-floating mb-3">
                                    <input type="text" name="name" className="form-control" id="floatingInput" placeholder="Enter your name"/>
                                    <label for="floatingInput" className="text-muted fs-6">Fullname</label>
                                </div>
                                <div className="form-floating mb-3 d-none">
                                    <input type="tel" className="form-control" id="floatingInput" placeholder="Enter your name"/>
                                    <label for="floatingInput" className="text-muted fs-6">Phone number</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" name="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                    <label for="floatingInput" className="text-muted fs-6">Email address</label>
                                </div>
                                <div className="form-floating mb-2">
                                    <textarea name="message" className="form-control"
                                        placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                    <label for="floatingTextarea" className="text-muted fs-6">Message</label>
                                </div>
                                <input type="checkbox" name="subscribe"/> <span className="text-muted fs-6"> subsbscribe to our news
                                    letter</span>
                                <button className="btn btn-success text-white w-100 p-2 mt-2" onClick={sendMessage}>Send Message</button>
                            </form>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Contact