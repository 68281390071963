import React, { useState, useEffect } from 'react';
import Navbar from './NavBar'
import Footer from './Footer'
import { Carousel } from './Carousel'
import ReactPlayer from 'react-player/lazy'
import { Link } from 'react-router-dom'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import WhyMaka from './WhyMaka';
import { Benefits } from './Faq'
import Partners from './Partners';
import Loader from './Loader';
import "./css/entry.css"


function EntryPage() {
    const [ spinner, setSpinner ] = useState(true);
    const [loadTwitter, setTwitter] = useState(false);
    useEffect(() => {
        setTimeout(() => setSpinner(false), 500);
        document.title = "Makapads | Home";
        /*
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        //script.charset="utf-8";
        //script.async = true;
        document.head.appendChild(script);
        setTwitter(true);
        */
    }, []);
  return (
    <>
        {spinner ? <Loader /> : <>
        <Navbar />
        <Carousel />
        <div className="row m-auto container lead">
            <div className="col-lg-7 div">
                <h3 className="fg fs-6 pt-4 pb-0 d-none fw-bold">About Us</h3>
                <h2 className="fs-4 pt-4 text-success fw-bold">We take care of your comfort.</h2>
                <p className="fs-6 text-muted pt-2">At MakaPads, we believe that affordable menstrual hygiene management is a key ingredient in the  lives of women and girls. Lack of affordable hygienic menstrual hygiene products, as well as the stigma associated with periods is a reason many girls drop out of school and ultimately fall short of their true potential.  That’s why we are placing our efforts on reaching the last mile with affordable menstrual hygiene products and knowledge about proper menstrual hygiene products.</p>
                <p className="fs-6 text-muted pt-4">We are working with partners in rural Uganda to ensure that affordable menstrual hygiene may be accessed in every village, every home and every school. We are also committed to developing a fully biodegradable sanitary pad and to create opportunities for women and youth across the country. </p>
                <ul style={{listStyle: "none"}}>
                    <li className="fs-6 text-muted" >M - Menstruation </li>
                    <li className="fs-6 text-muted">A - Administration</li>
                    <li className="fs-6 text-muted">K - Knowledge</li>
                    <li className="fs-6 text-muted">A - Affordability</li>
                </ul>
            </div>
            <div className='col-lg-5'>
                <h3 className="fg fs-6 pt-4 pb-0 fw-bold">Socials</h3>
                <h2 className="fs-5 text-info fw-bold"><i class="fa-brands fa-facebook"></i> Follow Us on Facebook</h2>
                <div className='col-lg-12 mb-2'>
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100087419582220%26mibextid%3DZbWKwL&tabs=timeline&width=440&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" className='facebook' height="450" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>

            </div>
        </div>
        <div className="mt-0 m-auto bg-white lead">{/*<!--whyMakaPads-->*/}
            <div className="div container pb-4 pt-2">
                <h3 className="fg fs-6 pt-4 pb-0 fw-bold">MakaPads- OUR HISTORY</h3>
                <h2 className="fs-4 text-success fw-bold pb-3">Started in the early 2006 by Dr. Moses Musaazi.</h2>
                <p className="fs-6 text-muted pt-2">In the early 2006 the MakaPads inventor, Dr. Moses Musaazi (the late), a remarkable innovator was challenged by an official from the Rockefeller Foundation to come up with a solution to address the issue of girls leaving school due to lack of menstrual hygiene products. With funding from the foundation, MakaPads was born. It utilized naturally growing papyrus as the absorbent material and met his criteria for meeting technical challenges of local people that include an intimate understanding of the people and their challenges, environmental sustainability and use of locally available materials.</p>
                <p className="fs-6 text-muted pt-2">MakaPads has been featured on BBC World Challenge, won an international award for empowering people (Siemens Stiftung) and a Presidential Award for Innovation among others. It is also a recipient of  investment from Innovations Against Poverty which challenges the private sector to develop inclusive businesses that address poverty and climate protection. </p>
            </div>
        </div>
        <div className='bg-light pb-4 pt-4'>
            <div className='row container-lg align-items-end m-auto'>
                <div className='col-lg-6'>
                    <h1 className='fs-4 text-success fw-bolder pt-4 pb-1'>Why MakaPads</h1>
                    <div className='col-lg-12 mb-4 rounded p-4 bg-white'>
                        <p className='lead fs-6'>Here is why you should use MakaPads as your sanitary Pad always</p>
                        <hr />
                        <WhyMaka />
                    </div>
                </div>
                <div className='col-lg-6 pb-4'>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="maka_pads"
                        options={{height: 300}}
                        />
                </div>
            </div>
            
        </div>
        <div className='partners bg-white'>
            <div className='container-lg row m-auto align-items-end'>
                <div className='col-lg-6 text-end'>
                    <h1 className='lead fs-4 pb-2 text-success fw-bolder'>Our Partners</h1>
                    <p className='lead fs-6'>While we are at the forefront of providing affordable sanitary pads, this has been possible by our partners.</p>
                </div>
                <div className='col-lg-6'>
                    <Partners />  
                </div>
            </div>
        </div>
        <Footer />
        </>}
    </>
  )
}

export default EntryPage