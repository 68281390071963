import React, { useState, useEffect } from 'react';
import { domain } from './Domain';
import Load from './Load';
export function Benefits(props){
    return(
        <div className='d-flex col-6'>
            <p><i class="fa-solid text-warning fa-square-check"></i></p>
            <p className='ms-3 text-secondary'>{props.benefit}</p>
        </div>
    )
}

function WhyMaka() {
    const [why, setWhy] = useState([]);
    const [loading, setLoading] = useState(true);
    // It will be executed before rendering

    async function fetchData() {
        const response = await fetch(`${domain}/why-maka`,{
            mode: "cors", // no-cors, *cors, same-origin,
            //credentials: 'include',
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setWhy(jsonData);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {loading ? <Load /> :
                <>
                {why.length > 0 && (
                    why.map(data => (
                    <Benefits 
                        key={data.id}
                        benefit={data.name}/>
                    ))
                )}
                </>
            }
        </div>
    )
}

export default WhyMaka