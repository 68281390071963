import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { domain } from './Domain';
import "./css/footer.css"

function Footer() {
    const [ subscribe, setSubscribing ] = useState(false);

    const subscribeEmail = () => {
        setSubscribing(true);
        let form = document.getElementById("subscription");
        let formData = new FormData(form);
        let email = formData.get("email");
        if(email.length === 0){
            document.getElementById("response").innerHTML = "Please Enter A Valid Email";
            setTimeout(function(){
                document.getElementById("response").innerHTML="";
            }, 5000);
        }else{
            const requestOptions = {
                method: 'POST',
                credentials: "omit",
                body: formData
            };
            fetch(`${domain}/subscriptions`, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                //console.log(data);
                form.reset();
                setSubscribing(false);
                if(data['email'] !='subscriptions with this email already exists.'){
                    document.getElementById("response").innerHTML = data['email']+ " subscribed successfully!";
                }else{
                    document.getElementById("response").innerHTML = data['email'];
                }
                setTimeout(function(){
                    document.getElementById("response").innerHTML="";
                }, 5000);
            })
            .catch((err) => console.log(err))
        }
    }

    return (
        <div className='bg-secondary footer pt-4 pb-4'>
            <div className='row row-cols-1 row-cols-lg-3 container-lg m-auto text-light'>
                <div className='col-lg-2'>
                    <img src='/images/logo.png' className='bg-white' alt='logo' />
                </div>
                <div className='col-lg-7'>
                    <h1 className='fs-4 fw-bold'>Contact Us</h1>
                    <div className='row row-cols-1 row-cols-lg-2'>
                        <div className='col'>
                            <h2 className='fs-5 pt-4'>Head Office</h2>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-house"></i></p>
                                <p className='ms-2 lead fs-6' >Plot 2266, Kawempe, Kalule Zone Kampala Uganda</p>
                            </div>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-mobile-screen-button"></i></p>
                                <p className='ms-2 lead fs-6'>0393228112</p>
                            </div>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-mobile-screen-button"></i></p>
                                <p><a className='text-light ms-2 lead fs-6 p-0 m-0' href='mailto:pkimera@t4tafrica.co'>pkimera@t4tafrica.co</a></p>
                            </div>
                        </div>
                        <div className='col'>
                            <h2 className='fs-5 pt-4'>Quick Links</h2>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-users"></i></p>
                                <p className='ms-2 lead fs-6'><Link className='text-white' to={"/about-us"}>About Us</Link></p>
                            </div>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-headset"></i></p>
                                <p className='ms-2 lead fs-6'><Link className='text-white' to={"/contact-us"}>Contact Us</Link></p>
                            </div>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-mobile-screen-button"></i></p>
                                <p className='ms-2 lead fs-6'><Link className='text-white' to={"/quotation-request"}>Request a quotation</Link></p>
                            </div>
                            <div className='d-flex ps-4'>
                                <p><i class="fa-solid fa-image"></i></p>
                                <p className='ms-2 lead fs-6'><Link className='text-white' to={"/gallery"}>View our gallery</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <h1 className='fs-4 fw-bold'>Subscribe & Follow</h1>
                    <p class="fs-6 lead">Subscribe to our News Letter.</p>
                    <form action="" id='subscription'>
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" name='email' placeholder="Email Address" aria-label="Email Address"
                                aria-describedby="button-addon2"/>
                            <button class="btn btn-danger" onClick={subscribeEmail} type="button" id="button-addon2">subscribe</button>
                        </div>
                        <p id='response' className='text-white fw-bolder' style={{fontSize: "0.875rem"}}></p>
                    </form>
                    <h1 className='fs-6 fw-bold'>Follow Us</h1>
                    <a href="javascript:void" className='d-block text-light'><i class="fa-brands fa-whatsapp text-light"></i> whatsapp</a>
                    <a href="https://www.facebook.com/profile.php?id=100087419582220&mibextid=ZbWKwL" className='d-block text-light'><i class="fa-brands fa-facebook text-light"></i> facebook</a>
                    <a href="https://twitter.com/maka_pads?t=-QRBN9sVBbJNRyofD5M20g&s=08" className='d-block text-light'><i class="fa-brands fa-twitter text-light"></i> twitter</a>
                    <a href="javascript:void" className='d-block text-light'><i class="fa-brands fa-instagram text-light"></i> instagram</a>
                </div>
            </div>
        </div>
    )
}

export default Footer