import React from 'react'
import './css/navBar.css'
import { Link } from 'react-router-dom'
export default function Navbar() {
    
  return (
    <>
        <div class="row m-auto top socials_main">
            <div class="col-lg-4 d-flex justify-content-start align-items-center">
                <a href="/"><i id = "fa-location-dot" class="fa-solid fa-location-dot text-danger"></i></a>
                <span className='fs-6 text-muted'>Kawempe, Kalule Zone Kampala Uganda</span>
            </div>
            <div class="col-lg-8 socials d-none d-md-flex justify-content-end align-items-center">
                <a href="javascript:none" className='text-info'><i class="fa-brands fa-whatsapp text-info"></i> Whatsapp</a>
                <a href="https://www.facebook.com/profile.php?id=100087419582220&mibextid=ZbWKwL" className='text-info'><i class="fa-brands fa-facebook text-info"></i> Facebook</a>
                <a href="https://twitter.com/maka_pads?t=-QRBN9sVBbJNRyofD5M20g&s=08" className='text-info'><i class="fa-brands fa-twitter text-info"></i> Twitter</a>
                <a href="javascript:none" className='fw-bold text-danger'><i class="fa fa-envelope"></i> Webmail</a>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg bg-dark navbar-dark">
            <div class="container-fluid container-lg">
                <Link to="/" className="navbar-brand fw-bold fs-6">MakaPads</Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className="nav-link active" aria-current="page"><li>Home</li></Link>
                        </li>
                        <li className="nav-item  dropdown d-none">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Sermons
                            </a>
                            <ul class="dropdown-menu">
                                <li><Link to="/sermons-audio" class="dropdown-item" >Audio Sermons</Link></li>
                                <li><Link to="/sermons-video" class="dropdown-item" >Video Sermons</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us" className="nav-link" aria-current="page"><li>About Us</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/faq" className="nav-link" aria-current="page"><li>FAQ</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/gallery" className="nav-link" aria-current="page"><li>Gallery</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/media" className="nav-link" aria-current="page"><li>Media</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact-us" className="nav-link" aria-current="page"><li>Contact Us</li></Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/quotation-request" className="nav-link" aria-current="page"><li>Request quotation</li></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
  )
}
